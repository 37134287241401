import { useEffect, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { routing } from '../providers/algoliaRouting';
import { useRefinedItems } from './useRefinedItems';

export const useListFilter = ({
  namedItems,
  attribute,
  urlParam,
  label,
  separator = ',',
}) => {
  const bySlug = Object.fromEntries(
    namedItems.map(({ value, slug }) => [slug, value])
  );
  const byValue = Object.fromEntries(
    namedItems.map(({ value, slug }) => [value, slug])
  );
  return () => {
    const { refine, items: __items } = useRefinementList({ attribute });
    const _items = useRefinedItems(attribute, __items);

    useEffect(() => {
      const handle = {
        stateToRoute: (route, state) => {
          state.filters = state.filters || {};
          const values = route?.refinementList?.[attribute] || [];
          if (values.length) {
            state.filters[urlParam] = values
              .map((value) => byValue[value])
              .join(separator);
          }
        },
        routeToState: (state, route) => {
          const slugs = state.filters?.[urlParam] || '';
          route.refinementList = route.refinementList || {};
          route.refinementList[attribute] = slugs
            .split(separator)
            .map((slug) => bySlug[slug])
            .filter((i) => i);
        },
      };
      routing.add(handle);
      return () => {
        routing.remove(handle);
      };
    }, []);

    const items = useMemo(() => {
      const byValue = Object.fromEntries(_items.map((i) => [i.value, i]));
      return namedItems.reduce((a, { value, label, slug, ...rest }) => {
        const i = byValue[value];
        if (i) a.push({ ...rest, ...i, label, slug });
        return a;
      }, []);
    }, [_items]);

    const current = useMemo(() => items.filter((i) => i.isRefined), [items]);

    return {
      type: 'multi',
      title: label,
      state: {
        refine,
        clear: () => current.map((i) => refine(i.value)),
        items,
        current,
        isRefined: current.length > 0,
        activeFilterLabel:
          current.length === 1
            ? current[0].filterLabel ?? current[0].label
            : label,
      },
    };
  };
};
